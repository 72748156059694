import React, { createContext, useContext, useState } from 'react';

const PaymentContext = createContext();

export const usePayment = () => useContext(PaymentContext);

export const PaymentProvider = ({ children }) => {
  const [baseTotal, setBaseTotal] = useState(0);  // Base total avant l'ajout des frais
  const [serviceFees] = useState(300);  // Frais de service fixés à 300
  const [invoiceNumber, setInvoiceNumber] = useState('');
  
  // Calcul du total final
  const total = parseFloat(baseTotal) + parseFloat(serviceFees);

  const setTotalAmount = (amount) => {
    setBaseTotal(amount);  // Mettre à jour le montant de base
  };

    const updateInvoiceNumber  = (number) => {
    setInvoiceNumber(number);
  };

  return (
    <PaymentContext.Provider value={{ total, serviceFees, setTotalAmount,invoiceNumber, setInvoiceNumber,updateInvoiceNumber   }}>
      {children}
    </PaymentContext.Provider>
  );
};
