import React from 'react';

function Banner() {
  return (
    <header className="banner">
      <img src="./images/logo.jpg" alt="FotoFaye Logo" />
      <h1>Bienvenue sur FotoFay</h1>
      <p>Prendre une photo de votre facture et procéder au paiement facilement.</p>
    </header>
  );
}

export default Banner;
