import React, { useState, useEffect } from 'react';
import { usePayment } from './PaymentContext';
import PaymentApi from './PaymentApi';
import './styles/Payment.css'; 

function Payment() {
  const { total, serviceFees, invoiceNumber } = usePayment();
  const [isValidInput, setIsValidInput] = useState(false);
  const [telClient, setTelClient] = useState('');

  useEffect(() => {
    const isValidPhone = telClient.length === 9;
    const hasInvoiceNumber = invoiceNumber && invoiceNumber.trim() !== '';
    setIsValidInput(isValidPhone && hasInvoiceNumber);
  }, [telClient, invoiceNumber]); // Enlever isValidInput des dépendances

  const handlePaymentStart = (method) => {
    console.log(`Le paiement a commencé avec la méthode : ${method}`);
  };

  const handlePaymentEnd = () => {
    console.log('Le paiement est terminé');
  };

  const handleTelInputChange = (e) => {
    const input = e.target.value;
    if (input.length <= 9 && /^\d*$/.test(input)) {
      setTelClient(input);
    }
  };

  return (
    <section className="payment">
      <section className="element.payment">
        <input
          className="text-saisi"
          type="text"
          placeholder="Frais de service"
          value={`Frais de service: ${serviceFees} frs`}
          readOnly
        />
        <p>Vérifier si le montant de la facture est correctement renseigné</p>
        <input
          className="text-saisi"
          type="text"
          placeholder="Total à payer"
          value={`Net: ${total} frs`}
          readOnly
        />
      </section>
      <p>Choisir votre moyen de paiement avec votre n°</p>
      <input
        className="text-saisi"
        type="tel"
        placeholder="Entrez votre numéro de téléphone"
        value={telClient}
        onChange={handleTelInputChange}
        maxLength={9}
      />
      <p className="tel-paie">{telClient}</p>
      <hr className="separator" />
      <div className="payment-options">
        <PaymentApi 
          factureNumero={invoiceNumber}
          telClient={telClient}
          montant={total}
          method="om"
          onPaymentStart={handlePaymentStart}
          onPaymentEnd={handlePaymentEnd} 
          isButtonDisabled={!isValidInput}
        />
        <PaymentApi 
          factureNumero={invoiceNumber}
          telClient={telClient}
          montant={total}
          method="wave"
          onPaymentStart={handlePaymentStart}
          onPaymentEnd={handlePaymentEnd} 
          isButtonDisabled={!isValidInput}
        />
        <PaymentApi 
          factureNumero={invoiceNumber}
          telClient={telClient}
          montant={total}
          method="free"
          onPaymentStart={handlePaymentStart}
          onPaymentEnd={handlePaymentEnd} 
          isButtonDisabled={!isValidInput}
        />
      </div>
    </section>
  );
}

export default Payment;
