import React from 'react';
import Banner from './components/Banner';
import CaptureDetails from './components/CaptureDetails';
import Payment from './components/Payment';
import AdditionalInfo from './components/AdditionalInfo';
import { PaymentProvider } from './components/PaymentContext';
import FloatingButton from './components/FloatingButton'; // Ajustez le chemin selon votre structure


function App() {
  return (
    <div className="App">
      <Banner />
      <PaymentProvider>
      <CaptureDetails />
      <Payment />
      </PaymentProvider>
      <FloatingButton />
      <AdditionalInfo />
    </div>
  );
}

export default App;