import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import './styles/Capture.css';
import '../App.css';

import { usePayment } from './PaymentContext';
import { GoogleGenerativeAI } from "@google/generative-ai";

function CaptureDetails() {
  const { setTotalAmount,updateInvoiceNumber  } = usePayment(); // Utilisation du contexte de paiement
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [details, setDetails] = useState('');
  const webcamRef = useRef(null);
  const audioRef = useRef(null);
  const genAI = new GoogleGenerativeAI('AIzaSyA5sErpOBTtGJ4Cn4h_mdqLa9vuSMwQ-SE');

  const handleScan = () => {
    console.log("Opening camera for scanning");
    setIsCameraOpen(true);
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc.replace(/^data:image\/jpeg;base64,/, ""));
    setIsCameraOpen(false);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image')) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const imageBase64 = e.target.result.replace(/^data:image\/\w+;base64,/, '');
        setImageSrc(imageBase64);
        const imageDetails = await get_details_image(imageBase64);
        setDetails(imageDetails);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    console.log("Uploading and processing image");
    if (imageSrc) {
      const imageDetails = await get_details_image(imageSrc);
      setDetails(JSON.stringify(imageDetails, null, 2));
    }
  };

  async function get_details_image(imageSrc) {
    console.log("Sending image data to API");
    const prompt = `Analyse la facture sur cette image, extraie les infos suivantes :
    Nom de l'entreprise, N° de la facture, date, détails ou description,
    montant total. et enfin renvoie moi ces infos sous forme d'objet json `;

    const imagePart = {
      inlineData: {
        data: imageSrc,
        mimeType: "image/jpeg"
      },
    };

    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
    try {
      const result = await model.generateContent([prompt, imagePart]);
      const response = await result.response;
      const text = await response.text();
      console.log("Received response from API:", text);

      const jsonData = JSON.parse(text.replace(/`/g, '').replace(/json/g, ''));
      console.log("Parsed JSON data:", jsonData);
      return extractAndFormatDetails(jsonData);
    } catch (error) {
      console.error("Error calling GoogleGenerativeAI:", error);
      return { error: "Error in calling API", details: error };
    }
  }

  function extractAndFormatDetails(apiResponse) {
    try {
    const companyName   = apiResponse["Nom de l'entreprise"];
    const invoiceNumber = apiResponse["N° de la facture"];
    const date          = apiResponse["date"];
    const totalAmount   = apiResponse["montant total"];
    //const details       = apiResponse["details"];
    console.log("Parsed JSON data:", apiResponse); // Debugging parsed data


      if (!companyName || !invoiceNumber || !date || !totalAmount) {
        console.error('One or more required fields are missing');
        return "Erreur : un ou plusieurs champs nécessaires sont manquants."; 
         }
      setTotalAmount(totalAmount);
      updateInvoiceNumber(invoiceNumber);
      return `Vous allez payer un montant de ${totalAmount} à ${companyName} pour la facture n° ${invoiceNumber} émise le ${date}.`;
    } catch (error) {
      console.error('Error processing data:', error);
      return `Erreur lors du traitement des données : ${error}`;
    }
  }

  return (
    <section className="capture-details">
      {!isCameraOpen && (
        <>
          <button onClick={handleScan}>Scan</button>
          <input type="file" accept="image/*" onChange={handleFileUpload} />          
          <audio ref={audioRef} controls src="./conseils.mp3">
            Votre navigateur ne supporte pas l'élément audio.
          </audio>
        </>
      )}
      {isCameraOpen && (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={320}
            height={240}
          />
          <button onClick={captureImage}>Capture</button>
        </>
      )}
      {imageSrc && (
        <div>
          <img src={`data:image/jpeg;base64,${imageSrc}`} alt="Captured" />
          <button onClick={handleUpload}>Upload and Scan</button>
        </div>
      )}
      <textarea value={details} readOnly style={{ width: '100%', height: '150px' }}></textarea>
    </section>
  );
}

export default CaptureDetails;
