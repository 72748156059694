import React from 'react';
import './styles/Additionalinfo.css'; 
function AdditionalInfo() {
  return (
    <section className="additional-info">
      <div>
        <h2>Contacts</h2>
        <p>Email: support@fotofaye.com</p>
      </div>
      <div>
        <h2>Politiques du service</h2>
        <p>...</p>
      </div>
      <div>
        <h2>Numéro du support</h2>
        <p>+123 456 789</p>
      </div>
      <div className="social-icons">
        <p>Web: www.icelabsoft.com</p>
        <i className="fab fa-facebook"></i>
        <i className="fab fa-twitter"></i>
        <i className="fab fa-instagram"></i>
      </div>
    </section>
  );
}

export default AdditionalInfo;
